moment = require('moment')
require('moment-timezone')
BigNumber = require('bignumber.js')
Clipboard = require('clipboard')
Partials = require('../../../lib/partials')
webAuthnApp = require('webauthn-simple-app')

window.Market.Entities.Wallets.Crypto =
  deposit: ($$, $this) ->
    class DepositView extends Backbone.View
      initialize: ->
        @$('.js-copy').each (i, element) =>
          $element = @$(element)
          return if $element.data('clipboard-instance')
          $input_element = $element.closest('.js-address-holder').find('.js-address')

          value = $input_element.val().trim()
          $element.attr('data-clipboard-text', value)
          clipboard = new Clipboard($element.get(0))
          $element.data('clipboard-instance', clipboard)
          asset_symbol = $element.data('asset_symbol')

          clipboard.on 'success', (e) =>
            $input_element.select()
            $element.attr('title', 'Copied!')
            Analytics.trackEvent(asset_symbol + ' - Deposit Copy Button Clicked')
            options = Tooltips.presetSimple()
            options = _.extend options,
              show:
                ready: true
              hide:
                event: false
            $element.qtip(options)
            setTimeout(->
              $element.qtip('destroy', true)
            , 2000)

    new DepositView el: $this

  withdraw: ($$, $this) ->
    class WithdrawView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @webauthnChallengePath = @$el.data('webauthnChallengePath')

        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')
        @balance = @$el.data('balance')
        @minWithdrawalAmount = @$el.data('min-withdrawal-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @amountDecimalPrecision = @$el.data('decimal-precision-for-withdrawals')
        @inputErrorClass = @$el.data('input-error-class')
        @$('.js-fee-tip-content').qtip(Tooltips.presetSimple())
        @$('.js-amount-tip-content').qtip(Tooltips.presetSimple())

        cleave = new Cleave('.js-amount',
          numeral: true,
          numeralDecimalScale: @decimalPrecision,
          numeralPositiveOnly: true,
          numeralThousandsGroupStyle: 'thousand')
        @$amount.data('cleaveInstance', cleave)

        Partials.with Partials.selector('wallet_addresses/shared/address_input_select'), (partial) =>
          # Save current address
          @walletAddress = partial.getValue()

          # Update on change & Validate
          partial.on 'change', (data) =>
            @walletAddress = partial.getValue()
            @_validate()

      _round: (amountStr) ->
        # round to the right number of decimal places
        BigNumber(amountStr).decimalPlaces(@decimalPrecision)

      _round_amount: (amountStr) ->
        # round to the right number of decimal places
        BigNumber(amountStr).decimalPlaces(@amountDecimalPrecision, 1)

      _normalizeAmount: (amountStr) ->
        amount = amountStr.replace(',', '')
        return BigNumber(null) if !amount

        amount = BigNumber(amount)
        return BigNumber(0.0) if !amount.isPositive()
        @_round_amount(amount)

      # Money input have a Cleave instance attached to them (ref: money_input.coffee)
      _amount: ->
        amount = parseFloat(@$amount.data('cleaveInstance').getRawValue())
        if isNaN(amount) then 0.0 else @_round_amount(amount)

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_normalizeAmount(@_amount().toString())

        return if amount.isZero()

        if amount.gt(@balance)
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount.lt(@minWithdrawalAmount)
          @$amountError.text(
            "The minimum withdrawal amount is #{@_round_amount(@minWithdrawalAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      _goBack: ->
        @$('.js-form-section').show()
        @$('.js-submit-section').hide()

      _submitWithdrawal: ($target) ->
        @$('form.js-submit-form').ajaxSubmit
          success: (response) =>
            @$('.js-hide-on-success').hide()
            @$('.js-show-on-success').show()

          error: (response) =>
            if response.responseJSON
              Rollbar.error('Error submitting withdrawal request', error: response.responseJSON)
              error = response.responseJSON.errors.message

              unless error.includes('address')
                error = 'Something went wrong, please try again shortly'
              @_goBack()
              @$('.js-errors').text(error).slideDown()
            else
              Tooltips.formServerError($target)

            $target.removeClass(@LOADING_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'submit': ->
          # turn off submit on enter key
          false

        'click .js-max-withdraw': ->
          @$amount.val(@balance)
          @_validate()

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$('.js-errors').slideUp()

          @$('form.js-review-form').ajaxSubmit
            success: (response) =>
              fee = @_round(response.fee)
              is_adjusted = (response.is_adjusted == true)
              adjusted_amount = @_normalizeAmount(response.amount)
              total_amount = @_round(adjusted_amount.plus(fee))
              free_withdrawal_text = " – Fee waived with free withdrawal credit"

              @$reviewErrors.hide()
              @$submitButton.removeClass(@DISABLED_CLASS)

              # due to mining fee, the funds might not be sufficient
              if total_amount.gt(@balance) || adjusted_amount.isZero()
                @$reviewErrors.text('Insufficient funds').show()
                @$submitButton.addClass(@DISABLED_CLASS)

              # fill the submit form
              @$('form.js-submit-form .js-submit-address-field').val(@walletAddress)
              @$('form.js-submit-form .js-submit-transfer-id-field').val(response.transfer_id)
              @$('form.js-submit-form .js-submit-amount-field').val(adjusted_amount.toFixed(@decimalPrecision))
              @$('form.js-submit-form .js-submit-fee-field').val(response.fee)
              @$('form.js-submit-form .js-submit-hash-field').val(response.hash)
              @$('form.js-submit-form .js-submit-free-withdrawal-field').val(response.free_withdrawal)


              # fill fields for review
              @$('.js-review-address').text(@walletAddress)
              @$('.js-review-transfer-id').text(response.transfer_id)
              @$('.js-review-amount').text(adjusted_amount.toFixed())
              @$('.js-review-fee').text(fee.toFixed())
              if response.free_withdrawal
                @$('.js-review-free-withdrawal').text(free_withdrawal_text)
                @$('.js-review-free-withdrawal').parent().removeClass('u-colorRed')
                @$('.js-review-free-withdrawal').parent().removeClass('u-colorGray7')
                @$('.js-review-free-withdrawal').parent().addClass('u-colorGreen')
              else
                @$('.js-review-free-withdrawal').text("")
                @$('.js-review-free-withdrawal').parent().removeClass('u-colorGreen')
              @$('.js-review-total-amount').text(total_amount.toFixed())
              if is_adjusted
                @$('.js-fee-tip').hide()
                @$('.js-amount-tip').show()
              else
                @$('.js-fee-tip').show()
                @$('.js-amount-tip').hide()

              # toggle content
              @$('.js-form-section').hide()
              @$('.js-submit-section').show()
              $target.removeClass(@LOADING_CLASS)

            error: (response) =>
              $target.removeClass(@LOADING_CLASS)
              error = response.responseJSON.errors.message
              unless error.includes('address') || error.includes('free withdraw') || error.includes('max withdrawable amount')
                Rollbar.error('Error reviewing withdrawal request', error: response.responseJSON)
                error = 'Something went wrong, please try again shortly'
              if error.includes('insufficient')
                error = 'Insufficient funds to fulfill withdrawal + fee'
              @$('.js-errors').text(error).slideDown()

        'click .js-back': ->
          @_goBack()

        'click .js-submit':  (e) ->

          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          @$('.js-error').hide()
          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          if @$('#js-webauthn-key').is(':visible')
            @webAuthn = new webAuthnApp.WebAuthnApp(
              loginChallengeEndpoint: @webauthnChallengePath,
              csrfToken: $.rails.csrfToken()
            )
            @webAuthn.username = "me"

            @webAuthn.requestLoginOptions()
              .then((serverMsg) => @webAuthn.get(serverMsg))
              .then (response) =>
                credentialAssertionResponse = webAuthnApp.CredentialAssertion.from(response)
                credentialAssertionResponse.encodeBinaryProperties();
                credentialAssertionResponse.validate()

                $('<input>').attr({
                  type: 'hidden',
                  value: credentialAssertionResponse.toString(),
                  name: 'webauthn_credential_response'
                }).appendTo('form.js-submit-form');

                @_submitWithdrawal($target)

              .catch (error) =>
                Rollbar.error('Webauthn error during withdrawal', error)
                @$('.js-error').show()
                $target.removeClass(@LOADING_CLASS)
          else # MFA code, no hardware key
            @_submitWithdrawal($target)


    new WithdrawView el: $this

  wrap: ($$, $this) ->
    class WrapView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minWrapAmount = @$el.data('min-wrap-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @wbtcFeePercent = @$el.data('wbtc-fee-percent')
        @tbtcFeePercent = @$el.data('tbtc-fee-percent')
        @inputErrorClass = @$el.data('input-error-class')
        @counterAssetSymbol = 'wbtc'

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _feePercent: ->
        if @counterAssetSymbol == 'wbtc'
          @wbtcFeePercent
        else
          @tbtcFeePercent

      _fee: ->
        @_amount() * @_feePercent()

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minWrapAmount
          @$amountError.text(
            "The minimum amount is #{@_round(@minWrapAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'click .js-wbtc_option': ->
          @$('.js-show_on_tbtc').hide()
          @$('.js-show_on_wbtc').show()
          @counterAssetSymbol = 'wbtc'

        'click .js-tbtc_option': ->
          @$('.js-show_on_wbtc').hide()
          @$('.js-show_on_tbtc').show()
          @counterAssetSymbol = 'tbtc'

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())
          @$('.js-review-fee').text(@_feePercent() * 100 + '%')
          @$('.js-review-total-amount').text(@_round(@_amount() - @_fee()))

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new WrapView el: $this

  unwrap: ($$, $this) ->
    class UnwrapView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minWrapAmount = @$el.data('min-wrap-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @feePercent = @$el.data('fee-percent')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _fee: ->
        @_amount() * @feePercent

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minWrapAmount
          @$amountError.text(
            "The minimum amount is #{@_round(@minWrapAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())
          @$('.js-review-fee').text(@feePercent * 100 + '%')
          @$('.js-review-total-amount').text(@_round(@_amount() - @_fee()))

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new UnwrapView el: $this

  convert: ($$, $this) ->
    class ConvertView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minAmount = @$el.data('min-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @feePercent = @$el.data('fee-percent')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _fee: ->
        Math.ceil(@_amount() * @feePercent * 1000) / 1000

      _reviewTotalAmount: ->
        Math.floor(@_round(@_amount() - @_fee()) * 1000)/1000

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minAmount
          @$amountError.text(
            "The minimum amount is #{@_round(@minAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())
          @$('.js-review-fee').text(@feePercent * 100 + '%')
          @$('.js-review-total-amount').text(@_reviewTotalAmount())

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new ConvertView el: $this

  convert_usdc: ($$, $this) ->
    class ConvertUsdcView extends Backbone.View
      LOADING_CLASS: 'c-button--loading'
      DISABLED_CLASS: 'c-button--disabled'

      initialize: ->
        @$amount = @$('.js-amount')
        @$amountError = @$('.js-amount-error')
        @$reviewButton = @$('.js-review')
        @$reviewErrors = @$('.js-review-errors')
        @$submitButton = @$('.js-submit')

        @balance = @$el.data('balance')
        @minAmount = @$el.data('min-amount')
        @decimalPrecision = @$el.data('decimal-precision')
        @feePercent = @$el.data('fee-percent')
        @inputErrorClass = @$el.data('input-error-class')

      _round: (amount) ->
        parseFloat(amount.toFixed(@decimalPrecision))

      _amount: ->
        amount = @$amount.val().replace(',', '')
        return 0 if !amount

        amount = parseFloat(amount)
        return 0.0 if isNaN(amount)
        @_round(amount)

      _fee: ->
        Math.ceil(@_amount() * @feePercent * 100) / 100

      _reviewTotalAmount: ->
        Math.floor(@_round(@_amount() - @_fee()) * 100)/100

      _clearInputs: ->
        @$amount.val('')

      _validate: ->
        @$reviewButton.addClass(@DISABLED_CLASS)
        @$amountError.hide()
        @$amount.removeClass(@inputErrorClass)

        amount = @_amount()

        return if amount == 0

        if amount > @balance
          @$amountError.text('Insufficient funds').show()
          @$amount.addClass(@inputErrorClass)
          return

        if amount < @minAmount
          @$amountError.text(
            "The minimum amount is #{@_round(@minAmount)}"
          ).show()
          @$amount.addClass(@inputErrorClass)
          return

        @$reviewButton.removeClass(@DISABLED_CLASS)

      events: ->
        'input .js-amount': ->
          @_validate()

        'click .js-review': (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS)

          # fill fields for review
          @$('.js-review-amount').text(@_amount())
          @$('.js-review-fee').text(@feePercent * 100 + '%')
          @$('.js-review-total-amount').text(@_reviewTotalAmount())

          # toggle content
          @$('.js-form-section').hide()
          @$('.js-submit-section').show()

        'click .js-back': ->
          @$reviewErrors.hide()

          @$('.js-form-section').show()
          @$('.js-submit-section').hide()

        'click .js-set-to-max': ->
          @$('.js-amount').val(@balance)
          @_validate()

        'click .js-submit':  (e) ->
          $target = @$(e.target)
          return if $target.hasClass(@DISABLED_CLASS) || $target.hasClass(@LOADING_CLASS)

          $target.addClass(@LOADING_CLASS)
          @$reviewErrors.hide()

          @$('form.js-submit-form').ajaxSubmit
            success: (response) =>
              @_clearInputs()
              @$('.js-hide-on-success').hide()
              @$('.js-show-on-success').show()

            error: (response) =>
              if response.responseJSON
                $target.removeClass(@LOADING_CLASS)
                @$reviewErrors.text(response.responseJSON.errors.message).fadeIn()
              else
                Tooltips.formServerError($target)

    new ConvertUsdcView el: $this

  past_transactions: ($$, $this) ->
    class PastTransactionsView extends Backbone.View
      DISABLED_CLASS = 'disabled'

      initialize: ->
        @page = 1
        @transactionPagesCount = @$el.data('transaction-pages-count')

      events: ->
        'click .js-cancel_withdrawal_request': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)

        'click .js-more': (e) ->
          e.preventDefault()
          $target = @$(e.target)
          return if $target.hasClass(DISABLED_CLASS)

          @page += 1
          $target.addClass(DISABLED_CLASS)
          url = $target.data('url')

          $.ajax
            url: url
            data:
              page: @page
            dataType: 'json'
            success: (data) =>
              if data.html
                $target.removeClass(DISABLED_CLASS)
                $target.hide() if @page >= @transactionPagesCount
                @$('.js-transactions-holder').append(data.html)

    new PastTransactionsView el: $this

  pending_transactions: ($$, $this) ->
    class PendingTransactionsView extends Backbone.View
      initialize: ->
        @$('.js-timestamp').each (_i, timestampElem) ->
          $timestampElem = $(timestampElem)
          time = moment($timestampElem.data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          timeForDisplay = timeWithZone.format('MMM D YYYY, h:mm A z')
          $timestampElem.text(timeForDisplay)

      events: ->
        'click .js-cancel_vault_deposit': (event) ->
          if confirm('Are you sure?')
            $target = $(event.target)
            $target.closest('form').ajaxSubmit
              success: (response) =>
                window.location.reload()
              error: (response) =>
                Tooltips.formServerError($target)


    new PendingTransactionsView el: $this
  distribution_schedule: ($$, $this) ->
    class DistributionScheduleView extends Backbone.View
      initialize: ->
        @$('.js-timestamp').each (_i, timestampElem) ->
          $timestampElem = $(timestampElem)
          time = moment($timestampElem.data('time'))
          timeWithZone = moment.tz(time, moment.tz.guess())
          timeForDisplay = timeWithZone.format('MMM D YYYY, h:mm A z')
          $timestampElem.text(timeForDisplay)

    new DistributionScheduleView el: $this
